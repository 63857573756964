ion-content{
    --background: none;
    --valign: middle;
}

.shopping-list-item {
    background: none;
    --background: none;
    opacity:1;
    color: white;
}

