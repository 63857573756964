ion-content{
    --background: none
}

.div-center {
    text-align: center;
}

.no-background {
    background: none;
    --background: none;
    opacity:1;
    color: white;
}

.home-button {
    width: 35vw;
    height: 75px;
    font-size: 14px;
    white-space: normal;
    word-wrap: break-word;
    margin: 5vw;
}